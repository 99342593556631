body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
p {
  font-family: Lato;
}

button.filterbutton {
  padding: 8px 16px;
  border: 1px solid black;
  background: white;
  border-radius: 8px;
  cursor: pointer;
}

.filtermenue {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.greyText {
  color: #999;
  margin-left: 10px; /* Hier kannst du die gewünschte graue Farbe einstellen */
}

.yourInput {
  margin-left: 40px;
  font-weight: bold;
}